import React, { useState } from 'react';

import { IconProps } from '@travel/icons';
import { Calendar } from '@travel/icons/ui';
import { IconColor } from '@travel/icons/withIconProps';
import { cx } from '@travel/utils';

import styles from './dateButton.module.scss';

export type CustomStyleType = 'iconWithDate' | 'labelWithDate' | 'iconAndLabelWithDate' | string;
type DateButtonProps = {
  /** Custom style for button */
  className: string;
  /** Custom height for button */
  customStyle: CustomStyleType;
  /** Button label to display */
  label: React.ReactNode;
  /** Date text to display */
  dateText: string;
  /** Flag to define whether the button is selected or not */
  isSelected: boolean;
  /** Callback function when button is clicked */
  onClick: (event: React.MouseEvent) => void;
  /** Callback function when button is focused */
  onFocus: (event: React.FocusEvent) => void;
  /** Props for Calendar icon */
  iconProps?: IconProps;
  /** Date component to specify custom format */
  DateComponent: React.ComponentType<{ value: string }>;
  /** Color when cursor is hovered */
  hoveredColor: IconColor;
  /** Color when cursor is not hovered */
  unhoveredColor: IconColor;
  /** Button ref */
  buttonRef?: React.RefObject<HTMLButtonElement>;
  /** ARIA label for button */
  ariaLabelButton?: string;
};

function DateButton(props: DateButtonProps) {
  const {
    className,
    customStyle,
    label,
    dateText,
    isSelected,
    iconProps,
    DateComponent,
    hoveredColor,
    unhoveredColor,
    buttonRef,
    ariaLabelButton,
    ...rest
  } = props;

  let isShowIcon, isShowLabel, isShowPlaceholder;

  switch (customStyle) {
    case 'iconWithDate':
      isShowIcon = true;
      isShowPlaceholder = true;
      break;
    case 'labelWithDate':
      isShowLabel = true;
      break;
    case 'iconAndLabelWithDate':
      isShowIcon = true;
      isShowLabel = true;
      isShowPlaceholder = true;
      break;
  }

  const displayText = isShowPlaceholder
    ? (dateText && <DateComponent value={dateText} />) || label
    : (dateText && <DateComponent value={dateText} />) || '';

  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      type="button"
      className={cx(
        className,
        styles.dateButton,
        dateText ? '' : styles.invalid,
        isSelected ? styles.active : '',
        isShowIcon ? styles.withIcon : '',
      )}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      data-testid="dateButton-button"
      ref={buttonRef}
      aria-label={ariaLabelButton}
      {...rest}
    >
      {isShowIcon && (
        <Calendar
          {...iconProps}
          className={cx(props.iconProps && props.iconProps.className, styles.icon)}
          color={isHovered ? hoveredColor : unhoveredColor}
          data-testid="dateButton-icon"
        />
      )}
      <div className={styles.container}>
        {isShowLabel && <span className={styles.label}>{label}:</span>}
        {displayText && (
          <span className={styles.text} data-testid="dateButton-dateText-span">
            {displayText}
          </span>
        )}
      </div>
    </button>
  );
}

DateButton.defaultProps = {
  className: '',
  customStyle: 'iconWithDate',
  dateText: null,
  isSelected: false,
  DateComponent: ({ value }: { value: string }) => value,
  hoveredColor: 'lincolnGreen',
  unhoveredColor: 'cilantro',
};

export default React.memo(DateButton);
