import React from 'react';

import { isNotEmptyArray } from '@travel/utils';

import { seoKeys } from '../../utils/seo';

type Props = {
  /** Number of current unit */
  unit: number;
  /** Number of current unit */
  adults: number;
  /** Number of current unit */
  childrenAges: Array<number>;
};

function FormHiddenInputs(props: Props) {
  return (
    <>
      <input
        className="input-unit"
        name={seoKeys.noOfUnits}
        data-testid="form-hiddenInput-unit"
        type="hidden"
        value={props.unit}
      />
      {Array(props.unit)
        .fill(0)
        .map((_item, index) => (
          <React.Fragment key={`adult-unit-${index}`}>
            <input
              className="input-adult"
              name={seoKeys.adults.replace('{{room}}', `${index + 1}`)}
              data-testid="form-hiddenInput-adult"
              type="hidden"
              value={props.adults}
            />
            {isNotEmptyArray(props.childrenAges) && (
              <>
                <input
                  className="input-child"
                  key={`children-unit-${index}`}
                  name={seoKeys.childNo.replace('{{room}}', `${index + 1}`)}
                  data-testid="form-hiddenInput-child"
                  type="hidden"
                  value={props.childrenAges.length}
                />
                {props.childrenAges.map((age, childIndex) => (
                  <input
                    className="input-child-age"
                    key={`childrenAge-unit-${index}-${childIndex}`}
                    name={seoKeys.childAge
                      .replace('{{room}}', `${index + 1}`)
                      .replace('{{childNum}}', `${childIndex + 1}`)}
                    data-testid="form-hiddenInput-child-age"
                    type="hidden"
                    value={age}
                  />
                ))}
              </>
            )}
          </React.Fragment>
        ))}
    </>
  );
}

export default FormHiddenInputs;
