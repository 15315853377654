import React from 'react';

import { IconProps } from '@travel/icons';
import { IconColor } from '@travel/icons/withIconProps';

import DateButton, { CustomStyleType } from '../DateButton';

import cx from '../../utils/classnames';

import styles from './dateButtonGroup.module.scss';

export type SelectedButton = 'startDate' | 'endDate' | string;
type Props = {
  className: string;
  /** Custom style for date button */
  buttonClassName: string;
  /** Custom props for date button icon */
  buttonIconProps?: IconProps;
  /** Custom height for button */
  customStyle?: CustomStyleType;
  /** Flag to define whether the highlight state will be shown or not */
  isShowSelectedState: boolean;
  /** String to define which button is selected */
  selectedButton: SelectedButton;
  /** Callback function when start date button is clicked */
  onClickStartDateButton: (event: React.MouseEvent) => void;
  /** Callback function when end date is clicked */
  onClickEndDateButton: (event: React.MouseEvent) => void;
  /** Callback function when start date button is focused */
  onFocusStartDateButton: (event: React.FocusEvent) => void;
  /** Callback function when end date is focused */
  onFocusEndDateButton: (event: React.FocusEvent) => void;
  /** End date of selected range */
  endDate?: string;
  /** Start date of selected range */
  startDate?: string;
  /** String to define title of start date */
  startDateLabel: React.ReactNode;
  /** String to define title of end date */
  endDateLabel: React.ReactNode;
  /** Date component to specify custom format */
  DateComponent: React.ComponentType<{ value: string }>;
  /** Color when cursor is hovered */
  hoveredColor?: IconColor;
  /** Color when cursor is not hovered */
  unhoveredColor?: IconColor;
  /** Start date button ref */
  startButtonRef?: React.RefObject<HTMLButtonElement>;
  /** End date button ref  */
  endButtonRef?: React.RefObject<HTMLButtonElement>;
  /** ARIA label for start date button */
  startButtonAriaLabel?: string;
  /** ARIA label for end date button */
  endButtonAriaLabel?: string;
};

function DateButtonGroup(props: Props) {
  const {
    buttonClassName,
    buttonIconProps,
    className,
    customStyle,
    endDate,
    isShowSelectedState,
    startDate,
    selectedButton,
    onClickStartDateButton,
    onClickEndDateButton,
    onFocusStartDateButton,
    onFocusEndDateButton,
    startDateLabel,
    endDateLabel,
    DateComponent,
    hoveredColor,
    unhoveredColor,
    startButtonRef,
    endButtonRef,
    startButtonAriaLabel,
    endButtonAriaLabel,
  } = props;

  return (
    <div
      className={cx(className, styles.dateButtonGroupWrapper)}
      data-testid="dateButtonGroup-wrapper"
    >
      <DateButton
        className={buttonClassName}
        iconProps={buttonIconProps}
        isSelected={isShowSelectedState && selectedButton === 'startDate'}
        customStyle={customStyle}
        label={startDateLabel}
        dateText={startDate}
        onClick={onClickStartDateButton}
        onFocus={onFocusStartDateButton}
        DateComponent={DateComponent}
        hoveredColor={hoveredColor}
        unhoveredColor={unhoveredColor}
        buttonRef={startButtonRef}
        ariaLabelButton={startButtonAriaLabel}
      />
      <div className={styles.seperateLine} />
      <DateButton
        className={buttonClassName}
        iconProps={buttonIconProps}
        isSelected={isShowSelectedState && selectedButton === 'endDate'}
        customStyle={customStyle}
        label={endDateLabel}
        dateText={endDate}
        onClick={onClickEndDateButton}
        onFocus={onFocusEndDateButton}
        DateComponent={DateComponent}
        hoveredColor={hoveredColor}
        unhoveredColor={unhoveredColor}
        buttonRef={endButtonRef}
        ariaLabelButton={endButtonAriaLabel}
      />
    </div>
  );
}

DateButtonGroup.defaultProps = {
  className: '',
  buttonClassName: '',
  selectedButton: 'checkin',
  isShowSelectedState: false,
  onClickStartDateButton: () => {},
  onClickEndDateButton: () => {},
  DateComponent: ({ value }: { value: string }) => value,
};

export default DateButtonGroup;
